// src/api.js
import axios from 'axios';

const API_BASE_URL = 'https://segwise-backend.swoyam.in'; // Your API base URL

export const loginUser  = async (credentials) => {
    const { username, password } = credentials;
    const response = await axios.post(`${API_BASE_URL}/login?username=${username}&password=${password}`);
    return response; // Assuming the response contains the token
};

export const fetchDataByDate = async (date, token) => {
    try{
        const response = await axios.get(`${API_BASE_URL}/get-comments?date_filter=${date}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    }catch(error){
        if (error.response) {
            // The request was made and the server responded with a status code
            return { status: error.response.status }; // Return the status code
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error in fetching data:', error.message);
            return { status_code: 500 }; // You can return a different status code for other errors
        }
    }
    
};