// src/components/DataDetail/DataDetail.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const commentTypes = ["ALL", "BUG", "COMPLAINT", "CRASHES", "PRAISES", "OTHER"]; // Define comment types

const DataDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data, apiResponse } = location.state || {}; // Use optional chaining to avoid destructuring undefined

    // State for selected comment type
    const [selectedCommentType, setSelectedCommentType] = useState("ALL"); 

    // Handle unauthorized response
    useEffect(() => {
        if (apiResponse && apiResponse.status_code === 401) {
            alert("Unauthorized access. Please log in.");
            navigate('/login'); // Redirect to login page
        }
    }, [apiResponse, navigate]); // Dependency array includes apiResponse and navigate

    // Check if data is available
    if (!data) {
        return <div>No data available</div>; // Handle the case when data is not available
    }

    const { summary, stats, comments } = data;

    // Function to map score to label
    const getScoreLabel = (score) => {
        switch (score) {
            case 0:
                return 'BUG';
            case 1:
                return 'COMPLAINT';
            case 2:
                return 'CRASHES';
            case 3:
                return 'PRAISES';
            case 4:
                return 'OTHER';
            default:
                return 'UNKNOWN'; // Handle unexpected scores
        }
    };

    // Check API response status for 404
    if (apiResponse && apiResponse.status_code === 404) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
                <div className="bg-white p-6 rounded shadow-md w-full max-w-2xl">
                    <h2 className="text-2xl font-bold mb-4">Comments are being processed</h2>
                    <p>Please wait after some time.</p>
                </div>
            </div>
        );
    }

    // Filter comments based on selected comment type
    const filteredComments = selectedCommentType === "ALL" 
        ? comments 
        : comments.filter(comment => getScoreLabel(comment.score) === selectedCommentType);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <div className="bg-white p-6 rounded shadow-md w-full max-w-2xl">
                <h2 className="text-2xl font-bold mb-4">Data Summary</h2>
                <p className="mb-4">{summary}</p>

                <h3 className="text-xl font-semibold mb-2">Statistics</h3>
                <ul className="mb-4">
                    {Object.entries(stats).map(([key, value]) => (
                        <li key={key} className="flex justify-between">
                            <span className="font-medium capitalize">{key}:</span>
                            <span>{value}</span>
                        </li>
                    ))}
                </ul>

                <h3 className="text-xl font-semibold mb-2">Comments</h3>
                
                {/* Dropdown for filtering comments */}
                <select
                    value={selectedCommentType}
                    onChange={(e) => setSelectedCommentType(e.target.value)}
                    className="mb-4 p-2 border border-gray-300 rounded"
                >
                    {commentTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                    ))}
                </select>

                <ul className="space-y-4">
                    {filteredComments.map((comment, index) => (
                        <li key={index} className="border-b pb-2">
                            <p className="font-medium">{comment.content}</p>
                            <p className="text-sm text-gray-500">
                                Score: {getScoreLabel(comment.score)} | Date: {comment.date} | Time: {comment.time}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DataDetail;