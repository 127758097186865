// src/components/Home/Home.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDataByDate } from '../api';
import { ThreeDots } from 'react-loader-spinner'; // Import ThreeDots instead of Loader

const Home = () => {
    const [dates, setDates] = useState([]);
    const [heading, setHeading] = useState("Select Date")
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        const today = new Date();
        const last7Days = Array.from({ length: 7 }, (_, i) => {
            const date = new Date(today);
            date.setDate(today.getDate() - (i + 1)); // Exclude today
            const day = String(date.getDate()).padStart(2, '0'); // Pad single digit days
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad single digit months
            const year = date.getFullYear();
            return `${day}-${month}-${year}`; // Format: DD-MM-YYYY
        });
        setDates(last7Days);
    }, []);

    const handleDateClick = async (date) => {
        const token = localStorage.getItem('token');
        setLoading(true); // Set loading to true before API call
        setHeading("Loading Data")
        try {
            const result = await fetchDataByDate(date, token);
            if (result.status === 401) {
                alert("Unauthorized, please login again");
                navigate('/'); // Redirect to login or home
            } else if (result.status === 201) {
                alert("Comments are not processed yet, try after some time");
            } else if (result.status === 200) { 
                navigate('/data-detail', { state: { data: result.data } });
            } else {
                alert("Something went wrong");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            alert("An error occurred while fetching data.");
        } finally {
            setLoading(false); // Set loading to false after API call
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h2 className="text-2xl font-bold mb-6">{heading}</h2>
            {loading ? (
                <ThreeDots 
                    color="#2BAD60" 
                    height={100} 
                    width={100} 
                />
            ) : (
                <ul className="space-y-2">
                    {dates.map((date) => (
                        <li
                            key={date}
                            onClick={() => handleDateClick(date)}
                            className="cursor-pointer bg-white p-4 rounded shadow hover:bg-gray-200 transition"
                        >
                            {date}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Home;