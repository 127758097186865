// src/App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/login';
import Home from './components/home'
import DataDetail from './components/data';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/home" element={<Home />} />
                <Route path="/data-detail" element={<DataDetail />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;